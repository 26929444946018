<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a class="navbar-brand" href="/">Age Sirdaryo Turizm</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/"
              >Yangi joy kirgizish</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/places"
              >Joylar</router-link
            >
          </li>
          <li class="nav-item">
            <button @click="logOut" class="btn btn-danger">
              Chiqish
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    logOut() {
      localStorage.removeItem('token');
      window.location.reload();
    },
  },
};
</script>
